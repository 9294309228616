import React, { Suspense, lazy, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import AOS from "aos";

import "aos/dist/aos.css";
import 'animate.css';
import './styles/pages/scss/_preloader.scss'

const Homepage = lazy(() => import("./pages/HomePage"));
const ProductList = lazy(() => import("./pages/productList"));
const ProductInformation = lazy(() => import("./pages/productInfo"));
const CartPage = lazy(() => import("./pages/cart"));
const MyAccount = lazy(() => import("./pages/MyAccount"));
const CheckOrder = lazy(() => import("./pages/CheckOrder"));
const Contact = lazy(() => import("./pages/contactForm"));
const LoginRegister = lazy(() => import("./pages/LoginRegister"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const VerifyResetTokenPassword = lazy(() => import("./pages/verifyResetPassword"));
const TokenError = lazy(() => import("./pages/TokenError"));

export default function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 800);
      return () => clearTimeout(timer);
    };

    handleRouteChange();
  }, [location.pathname]);

  return (
    <Suspense
      fallback={
        <div className="preloader-wrapper">
          <div className="preloader">
            <span></span>
            <span></span>
          </div>
        </div>
      }
    >
      {loading ? (
        <div className="preloader-wrapper">
          <div className="preloader">
            <span></span>
            <span></span>
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/*" element={<Homepage />} />
          <Route path="my-account" element={<MyAccount />} />
          <Route path="products" element={<ProductList />} />
          <Route path="cart" element={<CartPage />} />
          <Route path="check-order" element={<CheckOrder />} />
          <Route path="contact" element={<Contact />} />
          <Route path="products/:productId" element={<ProductInformation />} />
          <Route path="login-register" element={<LoginRegister />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="verify-reset-token/:id/:token" element={<VerifyResetTokenPassword />} />
          <Route path="reset-password/:id/:token" element={<ResetPassword />}></Route>
          <Route path="token-error" element={<TokenError />}></Route>
        </Routes>
      )}
    </Suspense>
  );
}
