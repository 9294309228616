// default get product
export const PRODUCT_FETCH_PENDING = "PRODUCT_FETCH_PENDING";

export const PRODUCT_FETCH_SUCCESS = "PRODUCT_FETCH_SUCCESS";

export const PRODUCT_FETCH_ERROR = "PRODUCT_FETCH_ERROR";

export const PRODUCT_PAGE_CHANGE = "PRODUCT_PAGE_CHANGE";

// filter
export const FILTER_NAME_CHANGE = "FILTER_NAME_CHANGE";

export const MAX_PRICE_CHANGE = "MAX_PRICE_CHANGE";

export const MIN_PRICE_CHANGE = "MIN_PRICE_CHANGE";

export const CATEGORY_CHANGE = "CATEGORY_CHANGE";

export const SORT_CHANGE = "SORT_CHANGE";

export const FILTER_CHANGE = 'FILTER_CHANGE';

export const FETCH_FILTER_PRODUCT = 'FETCH_FILTER_PRODUCT';

export const RESET_PAGE_FILTER = 'RESET_PAGE_FILTER';

export const FETCH_FILTER_PENDING = 'FILTER_FETCH_PENDING';

export const FILTER_PAGE_CHANGE = "FILTER_PAGE_CHANGE";

export const FETCH_FILTER_DATA_ERROR = "FETCH_FILTER_DATA_ERROR";

// location

export const FETCH_CITY = 'FETCH_CITY';

export const FETCH_LOCATION_ERROR = 'FETCH_LOCATION_ERROR';

// general
export const LIMIT_CHANGE = "LIMIT_CHANGE";

export const PAGE_CHANGE = 'PAGE_CHANGE';

// related
export const RELATED_PRODUCT_FETCH_SUCCESS = 'RELATED_PRODUCT_FETCH_SUCCESS';
export const RELATED_PRODUCT_FETCH_ERROR = 'RELATED_PRODUCT_FETCH_ERROR';
